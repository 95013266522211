<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';

import { inject } from 'vue';

// Components
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';

// Icons
import saveIcon from '@i/save-white.svg';

const route = inject('route');
const props = defineProps({
    company: Object,
    user: Object,
    address: Object,
    vatRates: Object,
});

const form = useForm({
    ...props.company,
    vat_rate: props.company.vat_rate ?? 21,
});

const sendForm = () => {
    form.put(route('staff.companies.update', { id: props.company.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <FormSingleSettingBar :title="$t('Contact Details')">
        <FormKit type="form" id="company-update-form" @submit="sendForm" :actions="false">
            <div class="grid gap-x-8 md:grid-cols-2">
                <FormKit
                    type="tel"
                    name="phone_number"
                    :label="$t('Phone')"
                    v-model="form.phone_number"
                    :errors="form.errors.phone_number"
                    :value="form.phone_number"
                />
                <FormKit
                    type="email"
                    name="email"
                    :label="$t('E-mail')"
                    v-model="form.email"
                    :errors="form.errors.email"
                    :value="form.email"
                />

                <FormKit
                    type="text"
                    name="iban"
                    :label="$t('IBAN')"
                    v-model="form.iban"
                    :errors="form.errors.iban"
                    :value="form.iban"
                />

                <FormKit
                    type="text"
                    name="coc"
                    :label="$t('CoC')"
                    v-model="form.kvk_number"
                    :errors="form.errors.kvk_number"
                    :value="form.kvk_number"
                />

                <FormKit
                    type="text"
                    name="vat_number"
                    :label="$t('VAT number')"
                    v-model="form.vat_number"
                    :errors="form.errors.vat_number"
                    :value="form.vat_number"
                    :help="$t('Please apply the following format: “NL 123 456 789 B01”')"
                />

                <FormKit
                    type="select"
                    suffix-icon="down"
                    name="vat_rate"
                    :label="$t('VAT rate')"
                    :options="vatRates"
                    v-model="form.vat_rate"
                    :errors="form.errors.vat_rate"
                    :value="form.vat_rate"
                />
            </div>

            <div class="flex items-center justify-end w-full">
                <div class="">
                    <Button
                        type="button"
                        :processing="form.processing"
                        :recentlySuccessful="form.recentlySuccessful"
                        :text="$t('Save')"
                        :icon="saveIcon"
                        @click="sendForm"
                    />
                </div>
            </div>
        </FormKit>
    </FormSingleSettingBar>
</template>
